import { reactive, toRefs, getCurrentInstance, watch } from 'vue'
import { useRouter } from 'vue-router'

export const componentState = props => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const params = Object.assign(props)
  if (proxy.$Util.isEmpty(params.email)) {
    router.go(-1)
  }
  const state = reactive({
    secondTime1: 0, // 휴대폰
    endTime1: '', // 휴대폰
    sendCnt1: 0, // 휴대폰
    authValue1: '', // 휴대폰
    timer1: undefined, // 휴대폰
    remainTime1: '', // 휴대폰

    secondTime2: 0, // 이메일
    endTime2: '', // 이메일
    sendCnt2: 0, // 이메일
    authValue2: '', // 이메일
    timer2: undefined, // 이메일
    remainTime2: '', // 이메일

    userId: '',
    phone: '',
    email: params.email
  })
  const fnSendSms = async () => {
    if (!isValidForPhone()) return
    const res = await proxy.$UserSvc.postSendAuthValueByPhone({
      userId: state.email,
      phone: state.phone
    })
    if (res.resultCode === '0000') {
      alert(`인증번호가 ${state.sendCnt1 === 0 ? '발송' : '재발송'}되었습니다.`)
      state.sendCnt1++
    } else {
      alert(res.resultMsg)
    }
  }
  const fnSendEmail = async () => {
    if (!isValidForUserId()) return
    const res = await proxy.$UserSvc.postSendAuthValueByEmail({
      userId: state.userId
    })
    if (res.resultCode === '0000') {
      alert(`인증번호가 ${state.sendCnt2 === 0 ? '발송' : '재발송'}되었습니다.`)
      state.sendCnt2++
    } else {
      alert(res.resultMsg)
    }
  }
  const fnNext = async (type = 1) => {
    if (type === 1) {
      // 휴대폰으로 찾기
      if (!isValidForPhoneAndAuthValue()) return
      const params = {}
      params.userId = state.email
      params.phone = state.phone
      params.authValue = state.authValue1
      const res = await proxy.$UserSvc.postCompareAuthValueByPhone(params)
      if (res.resultCode === '0000') {
        await router.replace({
          name: 'find-password-form-index',
          params: { userId: state.email, authValue: state.authValue1 }
        })
      } else {
        alert(res.resultMsg)
      }
    } else {
      // 이메일로 찾기
      if (!isValidForUserIdAndAuthValue()) return
      const params = {}
      params.userId = state.userId
      params.authValue = state.authValue2
      const res = await proxy.$UserSvc.postCompareAuthValueByEmail(params)
      if (res.resultCode === '0000') {
        await router.replace({
          name: 'find-password-form-index',
          params: { userId: state.userId, authValue: state.authValue2 }
        })
      } else {
        alert(res.resultMsg)
      }
    }
  }

  const maskingPhone = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    if (!proxy.$Util.isEmpty(e.target.value) && e.target.value.length > 15) {
      e.target.value = e.target.value.substr(0, 15)
    }
    state.phone = String(e.target.value)
  }

  const maskingAuthValue1 = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    if (!proxy.$Util.isEmpty(e.target.value) && e.target.value.length > 6) {
      e.target.value = e.target.value.substr(0, 6)
    }
    state.authValue1 = String(e.target.value)
  }

  const maskingAuthValue2 = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    if (!proxy.$Util.isEmpty(e.target.value) && e.target.value.length > 6) {
      e.target.value = e.target.value.substr(0, 6)
    }
    state.authValue2 = String(e.target.value)
  }

  const isValidForPhone = () => {
    if (proxy.$Util.isEmpty(state.phone)) {
      alert('휴대폰번호를 입력해주세요.')
      return false
    }
    return true
  }
  const isValidForPhoneAndAuthValue = () => {
    if (proxy.$Util.isEmpty(state.authValue1)) {
      alert('인증번호를 정확히 입력해주세요.')
      return false
    }
    if (state.authValue1.length !== 6) {
      alert('인증번호를 정확히 입력해주세요.')
      return false
    }
    return true
  }
  const isValidForUserId = () => {
    if (proxy.$Util.isEmpty(state.userId)) {
      alert('이메일을 입력해주세요.')
      return false
    }
    return true
  }
  const isValidForUserIdAndAuthValue = () => {
    if (proxy.$Util.isEmpty(state.authValue2)) {
      alert('인증번호를 정확히 입력해주세요.')
      return false
    }
    if (state.authValue2.length !== 6) {
      alert('인증번호를 정확히 입력해주세요.')
      return false
    }
    return true
  }
  const fnFormatRemainTime = (type = 1) => {
    const now = proxy.$dayjs()
    if (type === 1) {
      const minute = state.endTime1.diff(now, 'minute')
      const second = state.endTime1.diff(now, 'second')
      state.remainTime1 = `${proxy.$Util.lpad(
        `${minute % 60}`,
        2,
        '0'
      )}:${proxy.$Util.lpad(`${second % 60}`, 2, '0')}`
    } else {
      const minute = state.endTime2.diff(now, 'minute')
      const second = state.endTime2.diff(now, 'second')
      state.remainTime2 = `${proxy.$Util.lpad(
        `${minute % 60}`,
        2,
        '0'
      )}:${proxy.$Util.lpad(`${second % 60}`, 2, '0')}`
    }
  }
  /** watch **/
  watch(
    () => state.sendCnt1,
    () => {
      if (!proxy.$Util.isEmpty(state.timer1)) {
        clearInterval(state.timer1)
        state.timer1 = undefined
        state.remainTime1 = ''
      }
      state.endTime1 = proxy
        .$dayjs()
        .add(
          proxy.$ConstCode.CODE_ENV_LIST.PHONE_AUTH_SECOND * 1000,
          'millisecond'
        )
      state.secondTime1 = proxy.$ConstCode.CODE_ENV_LIST.PHONE_AUTH_SECOND
      fnFormatRemainTime(1)
      state.timer1 = setInterval(() => {
        --state.secondTime1
      }, 1000)
    }
  )
  watch(
    () => state.sendCnt2,
    () => {
      if (!proxy.$Util.isEmpty(state.timer2)) {
        clearInterval(state.timer2)
        state.timer2 = undefined
        state.remainTime2 = ''
      }
      state.endTime2 = proxy
        .$dayjs()
        .add(
          proxy.$ConstCode.CODE_ENV_LIST.EMAIL_AUTH_SECOND * 1000,
          'millisecond'
        )
      state.secondTime2 = proxy.$ConstCode.CODE_ENV_LIST.EMAIL_AUTH_SECOND
      fnFormatRemainTime(2)
      state.timer2 = setInterval(() => {
        --state.secondTime2
      }, 1000)
    }
  )
  watch(
    () => state.secondTime1,
    val => {
      if (val < 1) {
        clearInterval(state.timer1)
        state.timer1 = undefined
        state.remainTime1 = ''
        state.authValue1 = ''
        alert('인증시간 초과 하였습니다.')
      } else {
        fnFormatRemainTime(1)
      }
    }
  )
  watch(
    () => state.secondTime2,
    val => {
      if (val < 1) {
        clearInterval(state.timer2)
        state.timer2 = undefined
        state.remainTime2 = ''
        state.authValue2 = ''
        alert('인증시간 초과 하였습니다.')
      } else {
        fnFormatRemainTime(2)
      }
    }
  )

  return {
    ...toRefs(state),
    fnSendSms,
    fnSendEmail,
    fnNext,
    maskingPhone,
    maskingAuthValue1,
    maskingAuthValue2
  }
}

<template>
  <div class="page_con">
    <div class="form_wrap">
      <div class="form">
        <ul class="form_ul">
          <li class="form_li">
            <div class="form_ttl">휴대폰으로 찾기</div>
            <div class="form_in">
              <div class="inline">
                <label class="input">
                  <input
                    type="number"
                    inputmode="decimal"
                    pattern="\d*"
                    placeholder="휴대폰 번호(숫자만 입력)"
                    :value="phone"
                    @input="maskingPhone"
                  />
                </label>
                <button class="btn" @click="fnSendSms">
                  {{ sendCnt1 === 0 ? '발송' : '재발송' }}
                </button>
              </div>
              <div class="inline">
                <label class="input">
                  <input
                    type="number"
                    inputmode="decimal"
                    pattern="\d*"
                    placeholder="인증번호(6자리 숫자)"
                    :value="authValue1"
                    @input="maskingAuthValue1"
                    :disabled="$Util.isEmpty(remainTime1)"
                  />
                </label>
                <div class="time_count end" v-if="!$Util.isEmpty(remainTime1)">
                  입력 시간 {{ remainTime1 }}
                </div>
                <!--'종료: end'-->
              </div>
              <button class="btn" @click="fnNext(1)">비밀번호 재설정</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="form_wrap">
      <div class="form">
        <ul class="form_ul">
          <li class="form_li">
            <div class="form_ttl">이메일로 찾기</div>
            <div class="form_in">
              <div class="inline">
                <label class="input">
                  <input
                    type="email"
                    placeholder="이메일 주소 입력"
                    v-model="userId"
                  />
                </label>
                <button class="btn" @click="fnSendEmail">
                  {{ sendCnt2 === 0 ? '발송' : '재발송' }}
                </button>
              </div>
              <div class="inline">
                <label class="input">
                  <input
                    type="number"
                    inputmode="decimal"
                    pattern="\d*"
                    placeholder="인증번호(6자리 숫자)"
                    :value="authValue2"
                    @input="maskingAuthValue2"
                    :disabled="$Util.isEmpty(remainTime2)"
                  />
                </label>
                <div class="time_count end" v-if="!$Util.isEmpty(remainTime2)">
                  입력 시간 {{ remainTime2 }}
                </div>
                <!--'종료: end'-->
              </div>
              <button class="btn" @click="fnNext(2)">비밀번호 재설정</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'component-find-id-done-index',
  props: {
    email: {
      type: [String],
      default: ''
    }
  },
  setup(props) {
    const state = componentState(props)
    return { ...state }
  }
}
</script>

<style scoped></style>
